import React from "react";
import { graphql } from "gatsby";
import { RichText } from 'prismic-reactjs';
import Layout from "../../components/layout";
import Title from "../../components/title";
import Promo from "../../components/promo";

export default ({ location, data }) => {

  const doc = data.prismic.allBlogs.edges.slice(0,1).pop();
    if(!doc) return null;
  
  return (

    <Layout
      path={location.pathname}>

      <Title
        title={doc.node.title[0].text}
        width="max-w-xs md:max-w-md lg:max-w-xl" />

      <div className="container max-w-3xl mx-auto">

        <div className="richtext mx-auto container space-y-8">

          {RichText.render(doc.node.text)}

        </div>

      </div>

      <Promo theme="light" />

    </Layout>

  )

}

export const query = graphql`
  query BlogQuery($uid: String) {
    prismic {
      allBlogs(uid: $uid) {
        edges {
          node {
            title
            text
          }
        }
      }
    }
  }
`